<template>
  <div class="flexcenter">
<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" :height=size viewBox="0 0 24 24" :width=size :fill=color><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g></svg>   </div>
</template>

<script>
export default {
  name: 'Check',
  props: {
    color:{type:String, default:"#000"},
    size:{type:String, default:"24px"}
  }
}
</script>

<style scoped>

</style>
