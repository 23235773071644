<template>
  <div>
  <navbar></navbar>
    <div class="mtop64"></div> 
    <div v-if="!$root.ready" class="space100"><loader></loader></div>
 
    <div v-for="layer,index in $root.layers.home" >
      <component v-bind:is="layer.name" :propertties="layer.props"></component>
    </div>
    <footerBottom></footerBottom>
  </div>
</template>

<script>
import footerBottom from '../components/footerBottom.vue'
export default {
  name: 'Start',
  components: { footerBottom},
  data: function () {
        return { 
        }
      },
  methods:{

  },created() {
    var vm = this;
    this.$root.loadRoute("home");
  this.$root.lang="PT"
  },mounted() {
  },unmounted() {
  }
}
</script>

<style scoped>



</style>
