<template>
  <div class="flexcenter">
  <svg xmlns="http://www.w3.org/2000/svg" :height=size viewBox="0 0 24 24" width="24px" :fill="color"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
   </div>
</template>

<script>
export default {
  name: 'Check',
  props: {
  color:{type:String, default:"#fff"},
    size:{type:String, default:"24px"},
  }
}
</script>

<style scoped>

</style>
