<template>
    <div>
      <nav id="navbar" >
        <div id=" " style="flex: 1;" @click="gotoprev()" >
          <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
         </div>
        <div style="flex: 8;" @click="goto('')"><h5>x</h5></div>
        <div style="flex: 1;">   </div>
      </nav>

    </div>
</template>

<script>
export default {
  name: 'NavbarLight',
  data: function () {
     return {

     }
   },  methods:{
     goto:function(link){
       this.$router.push("/"+link);
     },
     gotoprev:function(link){
       this.$router.go(-1)||this.$router.push('/');
     },


     },
  created() {

  }, mounted() {

  },
  beforeDestroy() {

  }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#navbar{
  height: 64px !important;
  width:100%;
  background:#fff;
  box-shadow: 0 0 20px 0 rgb(180 180 180 / 50%);
  display: flex;
    align-items: center;
}

#navbarmenu{
float: left;
padding: 15px;
}


</style>
