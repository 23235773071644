<template>
  <div class="">
    <h1 id="notfound">404</h1>
 </h3>
 <span v-if="$root.lang == 'PT'">
    A página que procura é inexistente ou pode ter expirado  
 </span>
 <span v-if="$root.lang == 'EN'">
    The page you're looking for does not exist or has expired 
 </span>

</h3>

  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<style>
 #notfound{ margin-top: 100px;}
</style>
