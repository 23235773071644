<template>
  <main id="app" >
    <div v-if="!$root.loggingin"><router-view/></div>
    <Login v-if="$root.loggingin"></Login>
    <div id="errorNotification" class="fade-in" v-if="blocked">Too many requests - try again later</div>
  </main>
</template>

<script>
//import '@/assets/style.css' 
export default {
  name: 'App',
  data: function () {
     return {
       blocked:false,
     }
   },  methods:{ 
     getAuth:function(){
       var vm = this;
       this.$root.axios.post(vm.$root.url+'/user/authenticate', {}, { withCredentials: true }).then(function (response) {
          console.log(response)
          if(response && response.data == 0){
              vm.$root.auth=false; 
          }
          if(response && response.data){ 
              vm.$root.auth=true;
              vm.$root.user= response.data;  
           }
              vm.$root.ready=true;
              return
           }).catch(function (error) {
                console.log(error);
             try {
               if(error.response.data == 'Too many requests - try again later'){vm.blocked=true;}
             } catch (e) {

             }
          });

     },

     },
  created() {
    document.dispatchEvent(new Event('render-event'))

    this.$root.auth=false; 
    this.getAuth(); 
    var vm = this;
      
    this.$root.$on('userlogsout', function(x){
      vm.$root.auth=false; 
        vm.$router.push('/')
        this.$root.axios.post(vm.$root.url+'/logout', {}, { withCredentials: true })
          .then(function (response) {
          }).catch(function (error) {});
      });



  }, mounted() {
   
  },


  }
  </script>


<style>
.textcenter{text-align:center !important;}
.positionAbsolute{
position:absolute;
}
.margin0auto{
margin:0 auto !important;
}
.textunderline{
text-decoration:underline !important;
}
.topmiddle23{
width: 100%;
  height: 100%;
  padding-top: 23vh;
}
.smallinfo{
  color: grey;
font-size: 12px;
}

.hoverunderline:hover{
    text-decoration: underline;
}
.separator{
  width:100%;
  background:#fafafa;
border-radius:6px;
padding:5px;
}
.unsetFontWeight{
font-weight: unset !important;
}
.width300{
max-width:300px;
}
.boldp{
font-weight:bold;
}
.flexfloatright{
display: flex;
text-align: right;
align-items: center;
}
.flexfloatleft{
display: flex;
text-align: left;
align-items: center;
}
.floatleft2{
    margin-left: auto;
}
.floatright2{
    margin-left: auto;
}
.pleft15{
padding-left:15px;
}.pleft10{
padding-left:10px;
}
.pleft5{
padding-left:5px;
}
.primaryBadge{
padding: 2px 8px;
    color: #fff;
    background: #017bfe;
    border-radius: 5px;
}
.plightcolor{
color:#5f788c;
}

.pbottom10{
  padding-bottom:10px;
}
.padding0{
padding:0 !important;
}

.outlinebox{
    padding: 10px;
    border: 2px solid #Fafafa;
    border-radius: 5px;
}
.outlinebox > p{
    margin: 10px 0px 5px;
}


.bold{font-weight:bold;}
.space64{margin-top:64px;}
.mtop64{margin-top:64px;}
.mleft10{
      margin-left: 10px;
}
.mright10{
      margin-right: 10px;
}
.justifystart{
  justify-content: start !important;
}
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
#errorNotification{
  box-shadow: 10px 6px 20px 0 rgb(160 160 160);
  position: fixed;
    bottom: 30px;
    right: 20px;
    background: #ed5f74;
    padding: 14px;
    border-radius: 6px;
    color: #fff;
}

.width100{
  width: 100%;
}
.gap1{ gap: 1px;}
.gap2{ gap: 2px;}
.gap5{ gap: 5px;}
.gap10{ gap: 10px;}

.underline{text-decoration: underline;}
.withAuto{width:auto !important;}
.margintop0{margin-top:0;}
.marginbottom0{margin-bottom:0;}
.marginbottom5{margin-bottom:5px;}

.cursorpointer{cursor:pointer; }
.margin0{margin:0; }
.flex{display:flex; }
.flex1 {flex:1;}
.flex2 {flex:2;}
.flex3 {flex:3;}
  .flex9 {flex:9;}
  .flex8 {flex:8;}
.white {
background-color: #fff;
border-color: transparent;
color: #000;
}

.rounded-white {
background: none;
border: 2px solid #fff;
border-radius: 33px !important;
color: #fff;
max-width:max-content;
}
.outline {
background: none;
border: 1px solid #fff;
border-radius: 13px;
color: #fff;
}
.outlineprimary {
background: none;
border: 1px solid #017bfe;
border-radius: 13px;
color: #017bfe;
}
.danger:disabled{
  color: #fff;
background-color: #6c757d;
border:none;
}
.primary:disabled {
    color: #fff;
    background-color: #6c757d;
    border:none;
}
.danger{
color: #fff;
background-color: #dc3545;
border:none;
}
.primary {
    color: #fff;
    background-color: #007bff;
        border: none;
}
.btn:active {
    outline: none;
    transform: scale(0.99);
}
.btn{
cursor: pointer;
font-size: 16px;
text-decoration: none;
display: flex;
-webkit-box-align: center;
align-items: center;
background-color: rgb(46, 64, 87);
color: rgb(255, 255, 255);
font-weight: 700;
padding: 12px 20px;
border-radius: 8px;
transition: background-color 200ms ease 0s;
margin: 0 auto;
    width: fit-content;
}

.textarea-input{

display: block;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 box-sizing: border-box;
 background-color: #f8f8f8 !important;
   border: 1px solid #f1f0f0 !important;
   font-weight: bold;
color: #2f4057;
}

.text-input{
display: block;
width: 100%;
height: calc(1.5em + .75rem + 2px);
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 box-sizing: border-box;
 background-color: #f8f8f8 !important;
   border: 1px solid #f1f0f0 !important;
   font-weight: bold;
   height:calc(1.5em + .75rem + 6px);
color: #2f4057;
}
.greybox{padding: 20px;border-radius: 6px;background: #fafafa;}
.textjustify{text-align: justify;}
.astyle{color: #2c3e50;text-decoration: none !important;}

.text-underline{
  text-decoration: underline;
  color: #007bff;
  cursor:pointer;
}
.fixp{
margin-bottom: 0;}
.sidespace{
padding-left: 10px;
padding-right: 10px;
}
.padding10{
padding: 10px;
}
.floatright{
    float:right;
}
.floatleft{
    float:left;
}

.size20{
    font-size:20px;
}
.textcenter{
    text-align:center;
}
.textright{
    text-align:right;
}
.textleft{
    text-align:left;
}
.flexstart{
align-items: flex-start;
}
.displayflexcenter{
align-items: center;
display: flex;
}
.displayflex{
    display:flex;
}
.jobbox{
    width: 80%;padding: 0px 10px;
    padding-bottom: 2px;
}
.jobboxbtn{
  font-size: 11px !important;
}
.nomarginbottom{
  margin-bottom:0 !important;
}

.flexcenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
textarea{
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
width: 100%;
}
.text-left{
text-align:left;
}
#input-style{
text-align:left;
}


.text-center{
text-align:center;
}

.center{
margin:0 auto;
text-align:center;
}
.pzero{
margin:0;
}

.selectionBoxSelected{
color:#fff !important;
background-color: #007bff !important;
}
#selectionBox{
  cursor:pointer;
  padding: 13px;
  background: #fafafa;
  margin-bottom: 2px;
  border-radius: 5px;
  font-weight: bold;
  color: #5f5f5f;
}

.greynote{
  background: #f5f5f5;
  border-radius: 6px;
  padding: 10px;
}
.greynote > p{
margin:0;
}
.astyleb{
  cursor:pointer;
  text-decoration: none;
  font-weight: bold;
  color: #62aca7;
}
.mtop10{
  margin-top:10px !important;
}
.mbottom10{
  margin-bottom:10px;
}.mtop100{
  margin-top:100px;
}
.mbottom100{
  margin-bottom:100px;
}
.mtop25{
  margin-top:25px;
}
.mbottom25{
  margin-bottom:25px;
}
.mtop50{
  margin-top:50px;
}
.mbottom50{
  margin-bottom:50px;
}

.top25{
  padding-top:25px;
}
.bottom25{
  padding-bottom:25px;
}
.endspace{
  margin-bottom:100px;
}
.middle{
 max-width:600px;
 margin:0 auto;
}
.mobileVisible{
  display:none;
}
.pcVisible{
  display:none ;
}





@media screen and (min-width: 778px) {
.max600{
  max-width: 600px;
  margin: 0 auto;
}
.outlinebox{

    padding: 30px ;

}
.invisiblebox{

    padding: 30px !important;

}
.middlex{
  margin-left: 23%;
  margin-right: 23%;
  padding: 0px 10px;
}
.pcVisible{
  display:unset;
}
  }
#cvcMobile{
padding-top: 20px;
}
.middle88{
max-width:600px;
margin:0 auto;
}
@media screen and (max-width: 777px) {
#cvcMobile{
  font-size: 13px;
  padding-top: 15px;
}
  .mobileVisible{
    display:unset;
  }
.middlex{
 max-width:95% ;
 margin:0 auto;
}
.middle{
 max-width:95% ;
 margin:0 auto;
}
.middle88{
 max-width:88% !important;
 margin:0 auto;
}
  }

.space50 {
  padding-top:50px;
  padding-bottom:50px;
}
.space25 {
  padding-top:25px;
  padding-bottom:25px;
}
.space10 {
  padding-top:10px;
  padding-bottom:10px;
}
.space100 {
  padding-top:100px;
  padding-bottom:100px;
}
.space250 {
  padding-top:250px;
  padding-bottom:250px;
}

*:focus {outline: none;}
input:focus{outline: none;}
input:required {box-shadow: none;}
input:invalid {box-shadow: none;}
html,body{word-break:break-word;margin:0 !important;width: 100%;height: 100%;}
*, :after, :before {box-sizing: border-box;}
</style>
