<template>
    <div>
      <nav id="navbar">
        <div class="pcVisible middle" id="pcnav">
        <h5 id="pcmenuleft " class="cursorpointer "   @click="goto('')" > <3  </h5>
        <span@click="$root.auth=!$root.auth"> {{c}}</span>
        <div id="pcmenuright" class="flex gap55">
          <h3 v-if="!$root.auth" style="width:70px;" class="flex1" @click="$root.navigateAway('store');">  <span v-if="$root.lang == 'PT'">Loja</span>
            <span v-if="$root.lang == 'EN'">Store</span> &nbsp;</h3>
          <h3 v-if="!$root.auth" class="flex1" @click="$root.navigateAway('sales');">  <span v-if="$root.lang == 'PT'">Vendas</span><span v-if="$root.lang == 'EN'">Sales</span></h3>
          <h3 v-if="$root.auth" class="flex1" @click="logout()">  <span v-if="$root.lang == 'PT'">Terminar Sessão</span><span v-if="$root.lang == 'EN'">Sign out</span></h3>
        </div>
      </div>
      <h5 class="cursorpointer flexcenter mobileVisible" id="navtitle" @click="goto('')" >
      x
      </h5>
      <svg  @click="menuopen=!menuopen" class="floatright mobileVisible" xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 24 24" width="32px" fill="#000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
      </nav>

      <div class="mobileVisible">
        <div id="nav-menu-mobile" v-bind:class="{'menuClosed':!menuopen}" >
          <div id="nav-menu-mobile-div">
            <div  @click="menuopen=false;"   class="floatright cursorpointer" id="closebtn">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
            </div>
            <h5  class="cursorpointer">x {{$root.auth}}</h5>
            <div id="menuLinks">



            <p  v-if="!$root.auth" v-bind:class="{routeSelected:$route.path=='/'}"@click="menuopen=false;$root.goto('')" >
              <span v-if="$root.lang == 'PT'"> Inicio</span>
              <span v-if="$root.lang == 'EN'">Start Page </span>
            </p>
        <p  v-if="!$root.auth" @click="menuopen=false;$root.navigateAway('store')">
          <span v-if="$root.lang == 'PT'">Loja</span>
          <span v-if="$root.lang == 'EN'">  Store</span>
        </p>
        <p  v-if="!$root.auth" @click="menuopen=false;$root.navigateAway('sales')">
          <span v-if="$root.lang == 'PT'">Venda Plataformas  </span>
          <span v-if="$root.lang == 'EN'"> Sell Platforms</span>
        </p>

           <p  v-if="$root.auth" v-bind:class="{routeSelected:$route.path=='/dashboard'}"@click="menuopen=false;$root.goto('dashboard')">
          <span v-if="$root.lang == 'PT'">Central</span>
          <span v-if="$root.lang == 'EN'">Dashboard</span>
        </p>

      <!--  <p v-bind:class="{routeSelected:$route.path=='/payment-methods'}"v-if="$root.auth"@click="menuopen=false;$root.goto('payment-methods')">
          <span v-if="$root.lang == 'PT'">Metodos de Pagamento</span>
          <span v-if="$root.lang == 'EN'">Payment methods</span>
        </p>

-->



        <p  v-if="$root.auth" v-bind:class="{routeSelected:$route.path=='/account-settings'}" @click="menuopen=false;$root.goto('account-settings')">
          <span v-if="$root.lang == 'PT'">Configurações</span>
          <span v-if="$root.lang == 'EN'">Settings</span>
        </p>
        <p  v-if="$root.auth" @click="menuopen=false;logout()">
          <span v-if="$root.lang == 'PT'">Terminar Sessão</span>
          <span v-if="$root.lang == 'EN'">Sign out</span>
        </p>

        <br>


      <!--
      <p  v-if="$root.auth" @click="menuopen=false;$root.goto('contact')" >
        <span v-if="$root.lang == 'PT'">Reportar problema</span>
        <span v-if="$root.lang == 'EN'">Report problem</span>
      </p>

      <p><b> Language</b>  </p>
        <div class="flexcenter">
        <p class="flex1 langbox" @click="setLang('PT')" v-bind:class="{'selectedLang':$root.lang == 'PT'}">PT</p>
         <p class="flex1 langbox" @click="setLang('EN')" v-bind:class="{'selectedLang':$root.lang == 'EN'}">EN</p>
        </div>-->
        </div>

        <br>
        </div>
        </div>
      </div>

    </div>
</template>

<script>
import Loader from '../components/loaderMini.vue'
export default {
  name: 'Navbar',
  components: {
    Loader
  },
  props: {
},
  data: function () {
     return {
      c:"",
       menuopen:false,  
       expanded:false,
     }
   },  methods:{
     logout:function(){
       this.$root.$emit('userlogsout');
     },setLang(x) {
        this.menuopen=false;
        this.$root.changeLang(x);
        return
     },  goto:function(link){
         this.$router.push("/"+link)
       },

     },created() {
      this.c = window.location.hostname;
     }
  }
</script>

<style scoped>
#mbottom32{    margin-bottom: 32px;}
.routeSelected{
    border-left: 2px solid #017bfe;
    padding-left: 18px !important;
}
#menuLinks > p {
  cursor:pointer;

width: fit-content;
padding-left: 12px;
margin: 0;
padding: 15px 0px 15px 20px;
width: 100%;
font-size: 19px;
}
#separator{
  width:100%;
  background:#fafafa;
border-radius:6px;
padding:5px;
}
#navtitle{
font-size: 15px;
margin: 0;
}
.langbox{
text-align: center;
    margin: 0;
}
#closebtn{
    padding: 0px 0px 0px 20px;
}


#navbar:before {
    z-index: -2;
    background: hsla(0,0%,100%,0) linear-gradient(
180deg,#f7faff,rgba(247,250,255,0));
    transition: -webkit-backdrop-filter .25s ease-out;
    transition: backdrop-filter .25s ease-out;
    transition: backdrop-filter .25s ease-out,-webkit-backdrop-filter .25s ease-out;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    pointer-events: none;
    -webkit-backdrop-filter: blur(7px) saturate(200%);
    backdrop-filter: blur(7px) saturate(200%);
}
@media screen and (min-width: 778px) {
  #nav-menu-mobile-div{
  text-align: left;
      padding: 7.23%;
  }
  #navbar{
  top: 0 !important;
  height: 64px !important;
  width: 100%;
  background: #ffffff91;
  box-shadow: 0 0 20px 0 rgb(180 180 180 / 50%);
  position: fixed;
  z-index: 99;
  padding: 0px 00px;
  }
  #pcnav{
        display: inline-block !important;
            width: 100%;    height: 64px;
  }
  #pcnav > h5{
    font-size: 17px;
        margin-top: 18px;
        display: inline-block;

        float: left;
    /*    margin-left: 23%; */
  }#pcmenuleft{
  /*  padding-left: 23%; */
    font-size: 15px;
    margin: 0;

    float: left;
/*     margin-left: 23%; */
  }
  #pcmenuright{
    float: right;

    /*margin-right: 23%;*/
    cursor:pointer;
      cursor:pointer;

      font-size: 15px;
  }
.gap55{    gap: 55px;}
  #nav-menu-mobile:before{
  z-index: -2;
      background: hsla(0,0%,100%,0) linear-gradient(
  180deg,#f7faff,rgba(247,250,255,0));
      transition: -webkit-backdrop-filter .25s ease-out;
      transition: backdrop-filter .25s ease-out;
      transition: backdrop-filter .25s ease-out, -webkit-backdrop-filter .25s ease-out;
      transition: backdrop-filter .25s ease-out,-webkit-backdrop-filter .25s ease-out;
      display: block;
      content: " ";
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      pointer-events: none;
      -webkit-backdrop-filter: blur(7px) saturate(200%);
      backdrop-filter: blur(7px) saturate(200%);
  }
  #nav-menu-mobile{
    max-width:23%;
  z-index: 999;
  position: fixed;
  top: 64px;
  left: 0;
  width: 50vw;
  height: 100vh;
  background: hsla(0,0%,100%,0) linear-gradient(
  180deg,#f7faff,rgba(247,250,255,0));
  box-shadow: 0px 0px 25px 0 rgb(32 51 80 / 0%);
  transform-origin: 0 0;
  transition: transform .5s cubic-bezier(.77,.2,.05,1);
  backdrop-filter: blur(4px) saturate(200%);
  }

}








  @media screen and (max-width: 778px) {
    #nav-menu-mobile-div{
    text-align: left;
        padding: 0 7.23%;
    }
    #navbar{
    top: 0 !important;
    height: 64px !important;
    width: 100%;
    background: #ffffff91;
    box-shadow: 0 0 20px 0 rgb(180 180 180 / 50%);
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 99;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 20px;
    }

    #nav-menu-mobile:before{
    z-index: -2;
        background: hsla(0,0%,100%,0) linear-gradient(
    180deg,#f7faff,rgba(247,250,255,0));
        transition: -webkit-backdrop-filter .25s ease-out;
        transition: backdrop-filter .25s ease-out;
        transition: backdrop-filter .25s ease-out, -webkit-backdrop-filter .25s ease-out;
        transition: backdrop-filter .25s ease-out,-webkit-backdrop-filter .25s ease-out;
        display: block;
        content: " ";
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        pointer-events: none;
        -webkit-backdrop-filter: blur(4px) saturate(200%);
        backdrop-filter: blur(4px) saturate(200%);
    }
    #nav-menu-mobile{
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    background: hsla(0,0%,100%,0) linear-gradient(
    180deg,#f7faff,rgba(247,250,255,0));
    box-shadow: 0px 0px 25px 0 rgb(32 51 80 / 0%);
    transform-origin: 0 0;
    transition: transform .5s cubic-bezier(.77,.2,.05,1);
    backdrop-filter: blur(4px) saturate(200%);
    }
  }

#navbarlogin{
  float: right;
  background: #d4dee7;
  margin-right: 10px;
  padding: 10px 20px;
  margin-top: 11px;
  border-radius: 5px;
  display: flex;
    align-items: center;
}
#navbarmenu {
float: left;
margin-left:5px;
}
.menuClosed{
transform: translate(-100%);
}




.selectedLang{
border-bottom: 2px solid #c3c0c0;
}
</style>
