import Vue from 'vue'
import App from './App.vue'
const axios = require('axios');
import router from './router'
import error from './components/error.vue'
import Check from './components/check.vue'
import Cancel from './components/cancel.vue'
import RightArrow from './components/rightArrow.vue'
import Delete from './components/delete.vue'
import Loader from './components/loader.vue'
import NavbarLight from './components/navbarLight.vue'
import Navbar from './components/navbar.vue'
import Pagenotfound from './views/PageNotFound.vue' 



/*
    import all components once
*/
 


 
Vue.component('Check', Check);
Vue.component('Cancel', Cancel);
Vue.component('Delete', Delete);
Vue.component('RightArrow', RightArrow);
Vue.component('error', error);
Vue.component('Loader', Loader);
Vue.component('NavbarLight', NavbarLight);
Vue.component('Navbar', Navbar);
Vue.component('Pagenotfound', Pagenotfound);
Vue.config.productionTip = false

new Vue({
  router,
  components: {
 },
  data:{
      layers:{
        home:{},
      },
      config:{},
      activeModels:[

      ],
      langs:[  {code:"en",name:"English"},
            {code:"es",name:"Español"}, 
            {code:"pt",name:"Português"},],
      errorList:[  
            {code:0,txt:{PT:"Ocurreu um erro", EN:"An error has occured"}},
            {code:1,txt:{PT:"Endereço de E-mail inválido", EN:"Invalid E-mail address"}},
            {code:2,txt:{PT:"Password invalida",EN:"Invalid password"}}, 
            {code:3,txt:{PT:"Conta de utilizador já registado.",EN:"An account already exists."}},
            {code:4,txt:{PT:"E-mail ou password invalido",EN:"Invalid e-mail or password combination"}}, 

            {code:99,txt:{EN:"You must accept terms and conditions and the privacy policy in order to use this service",PT:"Para poder utilizar a plataforma tem de aceitar os termos e condições e politica de privacidade ."}},
            {code:100,txt:{EN:"An error has occured",PT:"Ocurreu um erro."}},
            ],       
      brandName:"x",
      goto:function(link){this.$router.push("/"+link)}, 
      currentProduct:null,
      auth:false,
      cc:0,
     // authPath:false,
      loggingin:false,
      serverurl:"http://192.168.64.2:3000",
      url:"http://192.168.64.2:3000",
      user:{email:"user@email.com"},
      usertype:0,
      ready:false,
      routeNotFound:false, 
      axios:axios,
      buyProductAuth:function(product){
        if(this.ready && this.auth){
         
            //Logged in, post to server to process purchase
        }else{
            console.log("displaying login component")
            this.currentProduct=product;
            this.loggingin=true;
            //show login modal
        }
      },
      changeLang:function(lang){
        //if(lang != "PT"){lang = "EN"}
        this.lang=lang;
        this.axios.defaults.headers.post['lang'] = lang;
        if(typeof(Storage)!=="undefined"){localStorage.setItem("lang",this.lang);}
        return
      },
      loadAutoLanguage:function(){
        if(!this.ready){return}
        var flag=null;
        if(typeof(Storage)!=="undefined")
        {
             flag = localStorage.getItem("lang");
              if(flag!=null){
                this.langs.forEach(function(item, i){
                  if(item.code == flag){this.lang='PT'; console.log("Found saved lang");}
                });
                if(this.lang=null){this.lang='EN'; console.log("didnt found saved lang");}
            //  this.axios.defaults.headers.post['lang'] = this.lang;
                localStorage.setItem("lang",this.lang);
              }
        }
        if(this.lang.length == 0){
          this.langs.forEach(function(item, i){
            if((window.navigator.userLanguage || window.navigator.language).indexOf(item.code)>-1){
              this.lang=item.code;
              console.log("Found auto lang");}
          });

          if(typeof(Storage)!=="undefined"){localStorage.setItem("lang",this.lang)}
        }
      },
      lang : '',
      checkLogin:function(shouldBeLoggedin){
        var vm = this,route = shouldBeLoggedin?'/':'/';
        var x = setInterval(function(){
          if(vm.$root.ready && vm.$root.auth != shouldBeLoggedin){
            vm.$router.push(route); 
            vm.$root.loggingin=true;
            clearInterval(x);}
        },23);
      },scrollTop:function(){
        window.scroll({ top: 0, left: 0 });
      },getDefaultErrMsg:function(){
        return this.lang=='PT'?"Ocurreu um erro":this.lang=='EN'?"An error occurred":"An error occurred";
      },loadRoute:function(route){
        var vm = this; 

        this.routeNotFound=false;
        console.log("Loading route "+route);
        this.axios.post(vm.$root.url+'/retrieve/page', {route:route}, { withCredentials: true }).then(function (response) {  
            console.log(response); 
      
                if(response.data == ""){vm.routeNotFound=true; return} 

                 // Object.assign( vm.layers,route.toLowerCase(),response.data );  
                Vue.set(vm.layers,route.toLowerCase(),response.data ) 
               //  vm.cc++;
              //  vm.layers[route.toLowerCase()] = response.data 
               //Object.assign( vm.layers[route.toLowerCase()],[]); 
  
              }).catch(function (error) {
                console.log(error);
                vm.routeNotFound=true; 
           });
      },
      

  },created(){

  //  this.axios.defaults.headers.common['lang'] = this.lang;
  }, 
  render: function (h) {  return h(App) }
}).$mount('#app')
