<template>
  <div id="home-footer" >

    <div class="sidespace middle">
      <div class="text-left fixpos">
        <p class="margin0"><b>{{$root.lang=='PT'?'Metodos de pagamento':$root.lang=='es'?'Metodos de pago':'Payment Methods'}}</b>

        </p>

      <img src="./cards.png" id="cards"/>
     
      </div>
      <div class="flex">
      <div class="text-left flex1" >
      <b>{{$root.lang=='PT'?'Suporte':$root.lang=='es'?'Apoyo':'Support'}}</b>
        <p   class="cursorpointer hoverunderline astyle" @click="$root.navigateAwayToMain('/contact')"> {{$root.lang=='PT'?'Contato':$root.lang=='es'?'Contacto':'Contact'}} </p>
        <p  class="cursorpointer hoverunderline astyle" @click="$root.navigateAwayToMain('/sitemap')">   Sitemap  </p>
      </div>

      <div class="text-left flex1">
      <b>{{$root.lang=='PT'?'Legal':$root.lang=='es'?'Legal':'Legal'}}</b>


        <p class="cursorpointer hoverunderline astyle" @click="$root.navigateAwayToMain('/privacy-policy')">  {{$root.lang=='PT'?'Privacidade':$root.lang=='es'?'Privacidad':'Privacy Policy'}} </p>

        <p  class="cursorpointer hoverunderline astyle" @click="$root.navigateAwayToMain('/terms-and-conditions')">  {{$root.lang=='PT'?'Termos':$root.lang=='es'?'Aviso legal':'Terms'}}  </p>


      </div>
      </div>
      <br>
      <div class="flex">
      <div class="text-left flex1">
      <b>Language</b>
      <div class="  gap10 textleft" v-if="$root.ready">
      <p v-for="lang in $root.langs"  class="  pointer  cursorpointer  flex  " @click="$root.changeLang(lang.code.toUpperCase())"><Check color="green"  v-if="$root.lang==lang.code.toUpperCase()"></Check> &nbsp;{{lang.name}} </p>

      </div>
      <div class="flex1">

      </div>


      </div>
      </div>

      <div id="mtop50" >
          <p class="textleft">©  <router-link to="/"  class="astyle"> {{$root.brandName}}</router-link>  </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixpos{
  height: 140px;
}
.badgepayment{
position: relative;
    top: -42px;
    background: #fafafa;
    width: fit-content;
    padding: 10px;
    border-radius: 6px;
    position: relative;
    top: -100px;
        color: #2f4057;
        font-size:13px;
    right: -130px;
}
#mtop50{
margin-top: 50px;
}
#cards{width: 140px;top: -14px;left: -14px;position: relative;}
#cardsold{width: 140px;top: -20px;left: -21px;position: relative;}

#home-footer{
 background:#fff !important;
 padding-top:100px;
 padding: 30px;
margin-top: 100px;
}
.invisiblebadge{
padding: 8px 12px;
}
.greybadge{
padding: 8px 12px;
background: #fafafa;
width: fit-content;
border-radius: 6px;
text-decoration: none;
text-align:center;
}
#home-footer  p{
    margin: revert !important;
}
</style>
