<template>
  <div class="lds-dual-ring-m"></div>
</template>

<script>
export default {
  name: 'LoaderMini'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lds-dual-ring-m {
  display: inline-block;
  width: 23px;
  height: 23px;

}
.lds-dual-ring-m:after {
  content: " ";
  display: block;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid #007bff;
  border-color: #007bff transparent #007bff transparent;
  animation: lds-dual-ring-m 1.2s linear infinite;
}
@keyframes lds-dual-ring-m {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>
